<template>
    <div class="addressInfo">
        <el-form class="search" style="display: flex !important">
            <el-form-item  label-width="90px" label="批次号">
                <el-input v-model:value="batch_id"/>
            </el-form-item>&nbsp;&nbsp;&nbsp;
            <el-button type="primary" icon="el-icon-search" @click="getTableData(1)">搜索</el-button>&nbsp;&nbsp;&nbsp;
            <el-button type="" icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form>
        <div class="infoBox">
            <div class="table-data">
                <TableLoading :loadShow="loadShow"></TableLoading>
                <el-table :data="tableData" border>
                    <el-table-column fixed prop="index" label="序号" width="70"></el-table-column>
                    <el-table-column prop="batch_id" label="批次号" width="90" />
                    <el-table-column prop="total_price" label="结算金额"/>
                    <el-table-column prop="open_name" label="开户名"/>
                    <el-table-column prop="bankId" label="银行卡号"/>
                    <el-table-column prop="bank_type" label="银行卡类型"/>
                    <el-table-column prop="open_bank" label="开户行"/>
                    <el-table-column prop="status" label="状态" >
                        <template slot-scope="{ row }">
                            <el-tag :type="row.status==1?'':row.status==2?'success':row.status==3?'':'error'">{{row.status==1?'未结算':row.status==2?'已结算':row.status==3?'结算审核中':'结算失败'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="err_msg" label="错误信息"  />
                    <el-table-column prop="InfoMsg" label="详情">
                        <template slot-scope="{ row }">
                            <el-tag style="cursor: pointer;" @click="viewInfoMsg(row.batch_id)">查看</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pageBox">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                    @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-sizes="[10]" :page-size="pageSize" :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="结算详情" :visible.sync="setteledInfoVisible" width="1400px">
            <div class="infoBox">
                <div class="table-data">
                    <TableLoading :loadShow="loadShow"></TableLoading>
                    <el-table :data="detailInfo.tableData" border>
                        <el-table-column fixed prop="index" label="序号" width="70"></el-table-column>
                        <el-table-column prop="order_id" label="订单号"/>
                        <el-table-column prop="real_price" label="订单收款" />
                        <el-table-column prop="money" label="所得金额"  />
                        <el-table-column prop="percentage" label="占比" />
                        <el-table-column prop="order_type" label="订单类型">
                            <template slot-scope="{ row }">
                                <el-tag>{{row.order_type==1?'自销订单':'外部订单'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="结算状态">
                            <template slot-scope="{ row }">
                                <el-tag :type="row.status==1?'':row.status==2?'success':row.status==3?'':'error'">{{row.status==1?'未结算':row.status==2?'已结算':row.status==3?'结算审核中':'结算失败'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="err_msg" label="失败原因"/>
                        <el-table-column prop="create_time" label="创建时间" />
                    </el-table>
                </div>
                <div class="pageBox">
                    <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                        @current-change="handleCurrentChangeDetial" :current-page="detailInfo.pageNum"
                        :page-sizes="[10]" :page-size="pageSize" :total="detailInfo.total">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { GetBatchSettledInfoApi, getSettledBatchDetail } from "@/common/api";
import TableLoading from "@/components/index/TableLoading.vue";
import StatusTab from "@/components/index/StatusTab.vue";

export default {
    data() {
        return {
            loadShow: false,
            pageNum: 1, // 当前页数
            total: 0, // 总页数
            pageSize: 10, // 每页显示条数
            tableData: [],
            batch_id: '',   //批次号
            setteledInfoVisible: false,
            detailInfo: {
                tableData: [],
                total: 0,
                pageNum: 1,
                batch_id: '',
            },
        };
    },
    components: {
        TableLoading,
        StatusTab,
    },
    mounted() {
        this.getTableData(1)
    },
    methods: {
        // 查看批次号详情
        async viewInfoMsg(batch_id){
            this.detailInfo.batch_id = batch_id
            let res = await getSettledBatchDetail({
                batch_id: this.detailInfo.batch_id,
                page: this.detailInfo.pageNum
            })
            if(!res) return
            res.data.forEach((element, index) => {
                element.index = index + 1 + (this.detailInfo.pageNum - 1) * 10;
            });
            this.detailInfo.tableData = res.data
            this.detailInfo.total = res.total;
            this.setteledInfoVisible = true
        },
        // 重置
        reset() {
            this.batch_id = "";
            this.pageNum = 1;
            this.getTableData();
        },
        // 当前页改变时
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getTableData();
        },
        // 当前页改变时
        handleCurrentChangeDetial(val) {
            this.detailInfo.pageNum = val;
            this.viewInfoMsg();
        },
        // 获取表格数据
        async getTableData(page) {
            if (page) this.pageNum = page;
            this.loadShow = true;
            let tableData = await GetBatchSettledInfoApi({
                batch_id: this.batch_id,
                page: this.pageNum,
            });
            this.loadShow = false;
            if (!tableData) return;
            tableData.data.forEach((element, index) => {
                element.index = index + 1 + (this.pageNum - 1) * 10;
            });
            this.tableData = tableData.data;
            this.total = tableData.total;
        },
    },
};
</script>

<style lang="less" scoped>

</style>
